.donation-bucket {
    border: 1px solid;
    border-radius: 20px;
    background-color: #FFF;
    padding: 5%;
    margin-top: 5%;
    width: 90%;
}

.donation-bucket-title {
    font-family: Helvetica;
    font-size: 48px;
    font-weight: bold;
    padding-top: 10%;
}

.donation-bucket-content {
    font-family: Helvetica;
    font-size: 24px;
    line-height: 48px;
    text-align: left;
}

@media screen and (max-width: 500px) {
  .donation-bucket-content {
    font-size: 18px;
  }
}
