.connect {

}

.connect-title {
  font-size: 48px;
  font-family: Special Elite;
}

.connect-content {
  font-size: 18px;
  font-family: Helvetica;
}

@media screen and (max-width: 1000px) {
  .connect-title {
    font-size: 48px;
  }

  .connect-content {
    font-size: 18px;
  }
}

@media screen and (max-width: 500px) {
  .connect-title {
    font-size: 28px;
  }

  .connect-content {
    font-size: 12px;
  }
}
