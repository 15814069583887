.a-link-menu {
  margin: 5px;
  padding: 25px;
  display: inline-block;
  color: #fff;
}
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 20px;
  top: 15px;
}
/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #56595e;
}
/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #60791f;
}

.burger-menu {
  margin: 5px;
  padding: 0.1%;
  width: 50px;
  height: 50px;
  border-radius: 20px;
}

.close-button {
  background: none;
  border: none;
  float: right;
  font-size: 20px;
  padding: 2vh;
}

.fade-in {
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.header {
  color: #000;
  font-family: Special Elite;
  width: 100%;
  padding-bottom: 1%;
  background-color: #FFF;
  position: fixed;
  z-index: 1000;

  :focus {
    outline: none;
  }
}

.header-link {
  margin-top: 15px;
}

.header-link a {
  color: #56595e;
  font-size: 1rem;
  text-decoration: none;
}

.header-link a:hover {
  color: #60791f;
}

.image-menu {
  width: 30%;
  -webkit-transition: width 3s;
}

.image-menu-closed {
  width: 40%;
  -webkit-transition: width 3s;
}

.logo {
  height: 40px;
  border-radius: 20px;
}

.logofloat-padding {
  padding-top: 25vh;
}

.logo-menu {
  background-color: #F2EDE2;
  height: 0;
  padding-top: 5vh;
  -webkit-transition: height 3s;
}

.logo-menu-down {
  height: 100vh;
  background-color: #F2EDE2;
  -webkit-transition: height 2s;
}

.menu {
  background-color: #56595e;
  border: none;

  :focus {
    outline: none;
  }
}

.menu-link {
  padding-top: 10vh;
  width: 15vw;
  height: 100vh;
  font-size: 0;
  -webkit-transition: font-size 2s;
}

.menu-link ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu-link-open {
  padding-top: 10vh;
  width: 15vw;
  height: 100vh;
  font-size: 20px;
  -webkit-transition: font-size 1s;
}

.menu-link-open ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
@media screen and (max-width: 1000px) {
  .a-link-menu {
    padding: 1vh 0 0;
  }

  .header {
    height: 60px;
  }

  .header-link {
    display: none;
  }

  .menu-link {
    width: 40vw;
    font-size: 18px;
  }

  .menu-link-open {
    width: 40vw;
    font-size: 18px;
  }
}
@media screen and (max-width: 500px) {
  .a-link-menu {
    padding: 1vh 0 0;
  }
}
