.page-heading {
  color: #e3e1e1;
  font-size: 20vh;
  font-style: italic;
  position: absolute;
  padding-top: 25vh;
  text-shadow: 3px 3px 0 #000;
}

.home-logo {
  margin-top: 10vh;
  width: 45vw;
  z-index: 100000;
}

.logo-div {
  margin-top: 5vh;
}

.logo-container {
  height: 100vh;
  padding-bottom: 20px;
}

.splash-shop-button {
  background-color: #333;
  border-color: #333;
}

.tagline {
  color: #e3e1e1;
  font-family: Helvetica;
  font-size: 70px;
  font-style: italic;
  padding-top: 35vh;
  text-shadow: 3px 3px 0 #000;
}

.title {
  font-family: 'Special Elite';
  font-size: 100px;
}

.title-div {
  color: #56595e;
  margin-bottom: 30vh;
  text-shadow: 3px 3px 0 #000;
}

.title-tagline {
  font-size: 30px;
}
@media screen and (max-width: 1000px) {
  .home-logo {
    width: 40vw;
    padding-top: 10vh;
  }

  .page-heading {
    font-size: 70px;
    padding-top: 10vh;
  }

  .splash-shop-button {
    margin-bottom: 5vh;
  }

  .tagline {
    font-size: 30px;
    padding-top: 35vh;
  }

  .title {
    font-size: 50px;
  }

  .title-div {
    margin-bottom: 0;
  }

  .title-tagline {
    font-size: 20px;
  }
}
@media screen and (max-width: 500px) {
  .home-logo {
    width: 45vw;
    padding-top: 2vh;
  }

  .page-heading {
    font-size: 50px;
    margin-top: 10vh;
  }

  .tagline {
    font-size: 50px;
    padding-top: 0;
  }

  .title {
    font-size: 50px;
  }

  .title-div {
    margin-top: 5vh;
  }
}
