.infocol-image {
  box-shadow: 0 24px 28px 0 rgba(0, 0, 0, 0.4), 0 26px 29px 0 rgba(0, 0, 0, 0.19);
}

.infocol-col {
  min-height: 30vh;
  padding: 10vh 2vh;
  text-shadow: 9px 9px 16px #000;
  background-repeat: no-repeat;
  background-size: cover;
}

.infocol-container {
  background-color: green;
}

.infocol-container p {
  font-size: 30px;
}
@media screen and (max-height: 376px) {
  .infocol-col {
    min-height: 100vh;
  }
}
@media screen and (max-width: 500px) {
  .infocol-col {
    padding: 5vh 2vh;
  }

  .infocol-container p {
    font-size: 20px;
  }
}
