.home-page-div {
  text-align: center;
  box-shadow: inset 0 0 100px black;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.image-div {
  margin: 30%;
}

.image-wrapper {
  background-color: #e3e1e1;
  display: flex;
  height: 100%;
  width: 50vw;
  transition: background-color 1s;
  position: relative;
  float: right;
}

.image-wrapper:hover {
  background-color: #C4C4C4;
;

  .flag-image {
    width: 21vw;
  }
}

.info-div {
  position: absolute;
  margin-top: 25%;
  width: 20vw;
  right: 40vw;
  text-align: left;
}

.info-image {
  position: absolute;
  left: 30vw;
  width: 20vw;
}

.info-section {
  background-color: #F2EDE2;
}
@media screen and (max-width: 1000px) {
  .home-logo {
    margin-top: 10px;
  }

  .home-title-div {
    height: 150%;
    padding-top: 80px;
  }

  .home-title-header {
    padding-top: 1vh;
    font-size: 32px;
    line-height: normal;
    margin-left: 0;
  }

  .info-div {
    font-size: 10px;
    top: 40vh;
    width: 60vw;
    right: 5px;
  }
}
@media screen and (max-width: 500px) {
  .home-logo {
    margin-top: 10px;
  }

  .home-title-div {
    min-height: 50%;
  }

  .home-title-image {
    height: 50%;
  }

  .home-title-header {
    padding-top: 0;
    font-size: 22px;
    line-height: normal;
  }

  .info-div {
    font-size: 10px;
    top: 20vh;
    width: 60vw;
    right: 5px;
  }

  .home-page-div {
    background-attachment: scroll;
  }

  .title-div {
    margin-top: 15px;
    margin-bottom: 20vh;
  }
}
