.product-info {
  min-height: 100vh;
  padding-top: 20vh;
  background: #F2EDE2;
  padding-bottom: 15%;
}

.product-info h1 {
  font-family: Special Elite;
}

.product-shop-link {
  color: #000;
  font-family: Helvetica;
  font-size: 24px;
  padding: 5%;
}

.product-shop-link:hover {
  color: #60791f;
}

.description-row {
  margin-top: 2%;
}
