.footer {
  color: #000;
  background-color: rgba(248, 247, 216, 0.4);
}

.footer-col-contact {
  color: #F2EDE2;
  font-family: Helvetica;
  font-size: 14px;
  margin-top: 15%;
}

.footer-col-contact p {
  text-align: left;
  margin: 0;
}

.footer-col-links {
  margin-top: 15%;
  font-family: Helvetica;
  font-size: 14px;
}

.footer-col-links a {
  color: #F2EDE2;
}

.footer-col-links p {
  line-height: 3;
  text-align: left;
}

.footer-col-media {
  font-family: Special Elite;
  font-size: 48px;
  margin-top: 35%;
}

.footer-col-media a {
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 0px;
}

.footer-col-media p {
  margin-bottom: 0px;
}

.footer-container {
  min-height: 558px;
  width: 100vw;
  padding: 15% 5% 5% 5%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #554032 54.17%);
}

.footer-link-div {
  width: 33%;
}

.footer-link-div a {
  margin: 0 !important;
  padding: 0 !important;
}

@media screen and (max-width: 1000px) {
  .footer {
    padding-bottom: 0;
  }

  .footer-col-contact {
    font-size: 10px;
    margin-top: 15%;
  }

  .footer-col-links {
    font-size: 10px;
    margin-top: 15%;
  }

  .footer-col-links a {
    color: #F2EDE2;
  }

  .footer-col-links p {
    line-height: 1;
  }

  .footer-col-media {
    font-size: 30px;
    margin-top: 25%;
  }

  .footer-container {
    min-height: 100vh;
    width: 100vw;
  }
}
@media screen and (max-width: 500px) {
  .footer {
    margin-top: -5vh;
  }
  .footer-col-media {
    margin-top: 5%;
  }

  .link-header {
    font-size: 17px;
  }

  .spacing {
    font-size: 13px;
  }

  .footer-container {
    min-height: 358px;
    width: 100vw;
  }
}
