#donations {
  background: #F2EDE2;
  min-height: 100vh;
}

#donations ul {
  list-style-type: dot;
  margin: 1;
  padding: 1;
}

.donation-footer {
  margin-top: 12vh;
}

.donation-header {
  font-family: Special Elite;
  font-size: 5vh;
  padding-top: 10%;
  background: #F2EDE2;
}

.donation-sub-header {
  font-size: 3vh;
  background-color: #000;
  color: #FFF;
  padding: 2%;
}

.donation-col {
  background: #FFF;
  border: solid;
  border-radius: 15px;
  margin: 5% 0;
  padding: 1%;
}

.donation-content {
    font-family: Helvetica;
    font-size: 24px;
    text-align: left;
}
@media screen and (max-width: 500px) {
  .donation-header {
    font-size: 4vh;
    padding-top: 15%;
  }

  .donation-content {
    font-size: 18px;
  }

  #donations {
    padding-top: 30px;
  }
}
