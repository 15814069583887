.about-page-header {
    background: #F2EDE2;
    padding-top: 25%;
}

.about-page-header-title {
    font-family: Special Elite;
    font-size: 48px;
}

.about-page-header-content {
    font-family: Helvetica;
    font-size: 24px;
}
@media screen and (max-width: 1000px) {
    .about-page-header-title {
        font-size: 24px;
    }

    .about-page-header-content {
        font-size: 12px;
    }
}
@media screen and (max-width: 500px) {

}
