.itemshop {
  margin: 5%;
  border: 1px solid #000000;
  border-radius: 20px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.itemshop-button {
  background-color: rgba(89,89,89,1);
  border-color: rgba(89,89,89,1);
}

.itemshop-description {
  font-size: 2vh;
  height: 2.5vh;
  line-height: 2.5vh;
  overflow: scroll;
  text-align: left;
}

.itemshop-link {
  margin: 3% 0 0 0;
}

.itemshop-price {
  display: block;
  text-align: right;
}

.itemshop-title {
  font-style: Helvetica;
  font-weight: bold;
  min-height: 8vh;
  line-height: 4vh;
  overflow: scroll;
  text-align: left;
}

.itemshop-image {
  width: 100%;
  height: 100%;
  float: left;
  object-fit: contain;
}

.itemshop-image-div {
  height: 30vh;
  margin-bottom: 1%;
}

.itemshop-jsx {
  padding: 5%;
}

@media screen and (max-height: 390px) {
  .itemshop-description {
    font-size: 15px;
    height: 18px;
    line-height: 18px;
  }

  .itemshop-image-div {
    height: 50vh;
  }
}
@media screen and (max-width: 500px) {
  .itemshop-image-div {
    height: 30vh;
  }
}
