.home-title-div {
  width: 100%;
  padding-top: 180px;
  height: 672px;
  background: #F2EDE2;
}

.home-title-image {
  width: 100%;
}

.home-title-header {
  padding-top: 295px;
  text-align: left;
  margin-left: -90px;

  font-family: Special Elite;
  font-size: 48px;
  line-height: 48px;
  color: #FFFFFF;
}
