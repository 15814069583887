.home-page-shop {
  background: #FFF;
}

.home-page-shop-unique {
  font-family: Special Elite;
  font-size: 48px;
  padding-top: 50%;
  text-align: left;
}

.home-page-shop-button {
  background: #60791F;
  border-radius: 30px;
  float: left;
}

.home-page-shop-button:hover {
  background: #303e0f;
}

.home-page-shop-image1 {
  width: 40%;
  position: relative;
  z-index: 10;
}

.home-page-shop-image2 {
  margin-top: -15%;
  float: right;
  width: 40%;
  position: relative;
  z-index: 9;
}

.home-page-shop-image3 {
  margin-top: -5%;
  width: 40%;
  position: relative;
  z-index: 11;
}

.image-stack {
  position: relative;
  padding: 10% 2%;
}
@media screen and (max-width: 1000px) {
  .home-page-shop-unique {
    font-size: 28px;
    padding-top: 5%;
  }
}
@media screen and (max-width: 500px) {

}
