.button-pad {
  margin-top: 5%;
}

.productshop {
  padding: 5%;
  border: 1px solid #000000;
  border-radius: 20px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.product-image {
  border-radius: 20px;
  width: 100%;
  height: 100%;
  float: left;
  object-fit: contain;
}

.product-display {
  min-height: 100vh;
  padding: 20vh 0 10vh 0;
  background: #F2EDE2;
}

.product-display h1 {
  font-family: Special Elite;
}

.product-display-button {
  margin: 5%;
  background: #60791F;
  border-radius: 30px;
  margin: 15%;
}

.product-description {
  color: #000;
  font-size: 20px;
  text-align: left;
}

.product-image-jsx {
  width: 20vw;
  box-shadow: 0 24px 28px 0 rgba(0, 0, 0, 0.4), 0 26px 29px 0 rgba(0, 0, 0, 0.19);
}

.jsx-img-div img {
  margin: 0 auto;
}

.description-row {
  margin-top: 2%;
}

@media screen and (max-width: 1000px) {
  .product-image-div {
    height: 20vh;
  }

  .product-description {
    font-size: 10px;
  }

  .product-display-button {
    font-size: 10px;
  }

  .right.carousel-control, .left.carousel-control {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .productshop {
    margin: 5%;
  }

  .product-image-jsx {
    width: 50vw;
  }

  .product-display-button {
    margin: 1%;
  }
}
@media screen and (max-width: 500px) {
  .product-image-div {
    height: 10vh;
  }
}
