.connect-form {
  border: 2px solid rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  border-radius: 10px;
  height: 40px;
  margin: 1.75%;
}

.connect-button {
  background: #60791F;
  border-radius: 30px;
  height: 52px;
  width: 132px;
}

.connect-button:hover {
  background: #303e0f;
}
