.flag-image2 {
  box-shadow: 0 24px 28px 0 rgba(0, 0, 0, 0.4), 0 26px 29px 0 rgba(0, 0, 0, 0.19);
  margin: 10vh;
  width: 20vw;
  transition: width 1s;
}

.flag-image2:hover {
  width: 21vw;
}

.gallery-header {
  padding-top: 50vh;
  position: fixed;
}

.gallery-words {
  left: 5vw;
  padding-top: 25vh;
  position: fixed;
  color: #f3edbe;
  font-size: 20vh;
  font-style: italic;
  text-shadow: 3px 3px 10px #000;
}

.gallery-background {
  height: 100vh;
  position: absolute;
  background-image: linear-gradient(rgba(243, 237, 190, .9), rgba(227, 225, 225, .75)), url("../../helpers/images/Stock/carp-banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  left: 0;
  right: 0;
}

body {
  margin: 0;
  font-family: Georgia;
}

#container .box {
  height: 100vh;
  display: inline-flex;
}

#container {
  box-shadow: inset 0 0 150px black;
  padding: 5%;
  overflow-y: scroll;
  overflow-x: hidden;
  transform: rotate(270deg) translateX(-100%);
  transform-origin: top left;
  position: absolute;
  width: 100vh;
  height: 100vw;
}

#container2 {
  transform: rotate(90deg) translateY(-100vh);
  transform-origin: top left;
  white-space: nowrap;
  font-size: 0;
}

.padding {
  margin-left: 5vw;
}

.one {
  margin-top: 50vh;
}

.two {
  margin-top: 20vh;
}

.three {
  margin-top: 35vh;
}

.four {
  margin-top: 55vh;
}
@media screen and (max-width: 1000px) {
  .flag-image2 {
    width: 30vh;
  }

  .flag-image2:hover {
    width: 31vh;
  }

  .gallery-words {
    font-size: 20vw;
  }
}
@media screen and (max-width: 500px) {}
