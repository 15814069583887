.inforow-wrapper {
  font-family: Helvetica;
  min-height: 50vh;
  max-height: 50vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
  margin-top: -5vh;
  // text-shadow: 3px 3px 16px #000;
}

.inforow-wrapper h1 {
  font-family: 'Special Elite';
}

.inforow-button {
  border: none;
  margin: 5%;
}

.inforow-div {
  position: relative;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  // box-shadow: inset 0 0 100px black;
}

.inforow-div h1 {
  font-size: 60px;
}

.inforow-div p {
  font-size: 25px;
}

.parallax::after {
  /* Display and position the pseudo-element */
  content: " ";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* Move the pseudo-element back away from the camera,
   * then scale it back up to fill the viewport.
   * Because the pseudo-element is further away, it appears to move more slowly, like in real life. */
  transform: translateZ(-1px) scale(1);
  /* Force the background image to fill the whole element. */
  background-size: 100%;
  /* Keep the image from overlapping sibling elements. */
  z-index: -1;
}
@media screen and (max-width: 1000px) {
  .inforow-div h1 {
    font-size: 40px;
    padding-top: 5%;
  }

  .inforow-button {
    margin: 1%;
  }

  .inforow-div p {
    font-size: 20px;
  }

  .inforow-wrapper {
    max-height: 100vh;
  }
}
@media screen and (max-height: 500px) {
  .inforow-wrapper {
    height: 100vh;
  }
}
@media screen and (max-width: 500px) {
  .inforow-div h1 {
    font-size: 30px;
  }

  .inforow-div p {
    font-size: 15px;
  }

  .inforow-wrapper {
    min-height: 20vh;
    max-height: 60vh;
  }
}
