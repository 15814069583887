.about-content-container {
  // margin-top: 10vh;
  padding: 0 0 30vh 5vw;
}

.about-content-row {
  color: #FFF;
  // width: 100vw;
  height: 30vh;
}
