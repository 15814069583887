.video {
  background: linear-gradient(90deg, rgba(89,89,89,1) 0%, rgba(134,131,105,1) 48%, rgba(227,225,225,1) 89%);
  min-height: 100vh;
  padding: 20vh 0;
}

.video-header {
  font-size: 40px;
}

.video-description {
  font-size: 20px;
}
