.home-page-intro {
  background: #F2EDE2;
  min-height: 50vh;
}

.home-page-intro p {
  text-align: left;
}

.home-page-blurb {
  font-size: 18px;
  padding-top: 20%;
}

.home-page-mission {
  font-size: 48px;
  padding-top: 5%;
}

.home-page-intro-button {
  background: #60791F;
  border-radius: 30px;
  float: left;
}

.home-page-intro-button:hover {
  background: #303e0f;
}

@media screen and (max-width: 1000px) {
  .home-page-blurb {
    font-size: 12px;
    padding-top: 2%;
  }
  .home-page-mission {
    font-size: 20px;
  }
  .home-page-intro-button {
    margin-bottom: 5%;
  }
}

@media screen and (max-width: 500px) {
  .home-page-blurb {
    font-size: 12px;
    padding-top: 2%;
  }
  .home-page-mission {
    font-size: 20px;
  }
  .home-page-intro-button {
    margin-bottom: 15%;
  }
}
